* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #202020
}

html {
  height: 100vh;
  width: 100vw;
}

body {
  font-family: 'Jost', sans-serif;
  height: 100%;
  padding: .5rem;
  width: 100%
}

#root {
  height: 100%;
  width: 100%;
}

.app {
  background-color: #c6a8af;
  height: 100%;
  width: 100%;
  /* margin: 3rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.home-page-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 50px;
}

header {
  height: 40px;
}

nav {
  display: flex;
  justify-content: space-between;

  /* gap: 2rem; */

}

.pre-line {
  white-space: pre-line;
}

.nav-links {
  display: flex;
  align-items: center;
}

h1,
p,
.kate,
svg,
button {
  transition: all .5s ease-in-out;
}

a {
  padding: .1rem .3rem;
  text-decoration: none;
  border-radius: 2px;
}

a:hover {
  background-color: #202020;
  color: #f2dce1;

}

.icon {
  display: flex;
  align-items: center;
}
svg{
  height: 1rem;
  width: 1rem;
}
svg:hover {
  fill: #f2dce1;
  
}

.card-button-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
}

.fake-button {
  border: solid 2px #202020;
  min-width: 68px;
  min-height: 32px;
  text-align: center;
}


h1 {
  font-size: 3rem;
  font-weight: 300;

}

.kate {
  font-weight: 500;
  font-size: 4.5rem;
  display: inline-block;
  letter-spacing: 1.2rem;
  line-height: 1;
}

.project-card,
.about-page-content {
  display: flex;
  background-color: #f2dce1;
  padding: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  align-items: center;
  width: 100%;
  max-width: 60rem;


}

.project-details,
.about-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.email-and-resume {
  display: flex;
  width: 100%;
}

button {
  border: none;
  padding: .1rem .3rem;
  background-color: #c6a8af;
  border-radius: 2px;
  font-weight: 300;
  color: #202020;
  margin: 0;
  cursor: pointer;
  /* height: 1rem; */
  text-align: center;
  text-decoration: none;

}

button:hover{
  background-color: #202020;
  color: #f2dce1;

}

img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: auto;
  max-width: 100%;

}

.project-card>h2,
.about-page-content>h2 {
  font-size: 1.75rem;
  font-weight: 500;
}



@media (min-width: 768px) {
  body {
    padding: 3rem;
  }

  .app {
    padding: 3rem
  }

  header {
    height: 75px;
  }

  h1 {
    font-size: 6rem;
  }

  .kate {
    font-size: 9rem;
  }

  .intro {
    font-size: 2rem;
  }

  a {
    font-size: 2rem;
  }
  svg{
    height: 2rem;
    width: 2rem;
  }
  button{
    height: 2.6rem;
    width: 2.6rem;
  }
  .nav-links {
    gap: 1rem;
  }

  .project-details,
  .about-details {
    flex-direction: row;
  }

  .project-image,
  .about-image {
    width: 50%;
  }

  .project-description,
  .about-description {
    width: 50%;
  }

  .project-description>p,
  .about-description>p,
  .email-and-resume>p,
  .a-tag-paragraph {
    font-size: 1.25rem;
  }

  .project-card>h2,
  .about-page-content>h2 {
    font-size: 3rem;
    font-weight: 500;
  }

  .fake-button {
    max-width: 68px;
    max-height: 32px;
    font-size: 16px;
  }
}